/**
 * Handler for input events for New Job Form
 *
 * This is where we call our custom API to manage Job requests
 */

import { doFetch as podstacksAPI } from '../../../../api/PodstacksAPI';
import {
  pageTimeout,
  getUrlParams,
  redirectToPage,
  // newBrowserTab,
} from '../../../../api/BrowserAPI';
import { trackPromise } from 'react-promise-tracker';


// Load podcast episode from URL slug
export const loadEpisode = async () => {
  const params = getUrlParams();
  console.log('Fetching episode: ' + params.jobId);
  let episode = null
  pageTimeout( () => { return episode == null })
  episode = await trackPromise(podstacksAPI(
    'podcasts',
    'getEpisodeData',
    { episode_id: params.jobId }
  ))
  return episode
};

// Play podcast episode in new window
// const playEpisode = async (episode) => {
//   newBrowserTab(episode.audio);
// };

// Update speaker names and number of speakers
export const updateSpeakerObj = (rawSpeakers) => {
  const speakers = rawSpeakers.split(' ');
  return {
    raw: rawSpeakers,
    speakers,
    numSpeakers: speakers.length,
  };
};

// Add job information to database, and do redirect to job page
// const addJob = async (jobObj, redirect) => {
//   console.log('Adding new job: ' + jobObj.epObj.title);
//   const { epObj: episode } = jobObj;

//   // Simplified API fetch
//   const newResults = await podstacksAPI(
//     'jobs',
//     'createJob',
//     null,
//     null,
//     {
//       job: {
//         jobId: episode.id, // Job ID is also the ListenNotes API episode_id
//         userId: episode.podcast.id, // This maps to the ListenNotes API podcast_id
//         status: 'JOB ADDED', // Job status
//         paymentStatus: 'UNPAID',
//         payment: {},
//         urls: { audio: episode.audio }, // (OBJ) URL object to various job resources (blog post, downloadable transcripts, etc)
//         awstJob: {}, // (OBJ) AWS Transcribe job settings object
//         episode: episode, // (OBJ) Podcast data episode object
//         customDict: jobObj.customDict, // Custom dictionary for transcription
//         audioSupport: episode.audioSupport, // (OBJ) Audio support object
//         speakerNames: jobObj.speakerObj.speakers, // Speaker names, can parse number of speakers as needed
//         exportFormats: 'md html pdf'.split(' '), // Output formats to generate for transcript
//       },
//     },
//     null,
//     null
//   );

//   // Update the user jobs array
//   const user = await podstacksAPI(
//     'users',
//     'getUser',
//     { userId: episode.podcast.id },
//     null,
//     null,
//     null,
//     null,
//     null
//   );
//   let { userId, jobs } = user;
//   // console.log(jobs)
//   if (Array.isArray(jobs)) {
//     jobs.push(episode.id);
//   } else {
//     jobs = [episode.id];
//   }
//   // console.log(jobs)
//   await podstacksAPI(
//     'users',
//     'updateUser',
//     { userId },
//     null,
//     { user: { jobs } },
//     null,
//     null
//   );
//   redirectToPage(redirect, {
//     jobId: episode.id,
//     userId: episode.podcast.id,
//   });
// };

// export const InputHandler = {
//   addJob,
//   loadEpisode,
//   playEpisode,
//   updateSpeakerObj,
// };
