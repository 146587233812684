// REACT AND GATSBY
import React from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Card from 'reusecore/src/elements/Card';
// STYLING
import { WidgetLayoutStyles } from './widget.styles';
// LOGIC AND HANDLERS
// NPM MODULES

// Default job layout for a single widget
// A widget is simply a card with a title and some default styling
const WidgetLayout = props => {
  // console.log(props)
  let { col } = WidgetLayoutStyles;
  if (props.width) col.width = props.width;
  return (
    <Box className="widget_layout" {...col} styles={{ height: '100%' }}>
      <Card>
        {props.title && <h4>{props.title}</h4>}
        {props.children}
      </Card>
    </Box>
  );
};

export default WidgetLayout;
