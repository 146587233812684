/**
 * Handler for input events for AudioWidget
 */

import { doFetch as podstacksAPI } from '../../../../api/PodstacksAPI';
import { getUrlParams, redirectToPage } from '../../../../api/BrowserAPI';
import { getUserId, getUser } from '../../../../api/UserLoginAPI';

// Get job item from database
const getJob = async () => {
  const { jobId, userId } = getUrlParams();
  console.log('Getting job: ' + jobId);
  return podstacksAPI(
    'jobs',
    'getJob',
    { jobId, userId },
    null,
    null,
    null,
    null,
    null
  );
};

export const InputHandler = {
  getJob,
  redirectToPage,
};
