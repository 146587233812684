// import styled from 'styled-components';

// export const JobWrapper = styled.section`
//   /* padding: 100px 0 0 0;*/
// `;
// JobWrapper.displayName = 'JobWrapper';

export const WidgetLayoutStyles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '10px',
    mb: '10px',
  },
  col: {
    width: [1, 1, 1, 1 / 3, 1 / 3], // Default width of a widget
    pb: ['10px', '10px', '20px', '20px'],
    // pr: ['10px', '10px', '20px', '20px'],
  },
};

export const WidgetContainerStyles = {
  row: {
    width: 1,
    flexBox: true,
    flexWrap: 'wrap',
    // alignItems: 'center',
    justifyContent: 'center',
    mt: '10px',
    mb: '10px',
  },
};
