// REACT AND GATSBY
import React, { useState } from 'react';

// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Card from 'reusecore/src/elements/Card';
import CheckBox from 'reusecore/src/elements/Checkbox';
import Button from 'reusecore/src/elements/Button';
import { Formik } from 'formik';
// import Image from 'reusecore/src/elements/Image';
import Input from 'reusecore/src/elements/Input';
import Container from 'common/src/components/UI/Container';

// STYLING
import { Styles } from './newJobForm.styles'; // CSS styling

// LOGIC AND HANDLERS
import { updateSpeakerObj } from './handlers'; // Code to handle user I/O

// NPM MODULES
import Dotdotdot from 'react-dotdotdot';

const MultipleSpeakerForm = ({ heading, episode }) => {
  // const [speakerObj, updateSpkrObj] = useState({
  //   raw: 'Luke Cage',
  //   speakers: [],
  //   numSpeakers: 0,
  // });
  const { row, widgetsRow, errorText, col } = Styles;
  const maxSpeakers = 3;

  return (
    <Container>
      <Box {...row}>
        <h3>{heading}</h3>
      </Box>

      <Formik
        initalValues={{ speakers: 'poop', introVoice: false }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          setTimeout(() => {
            console.log(values);
            const customDict = '';
            const speakerObj = updateSpeakerObj(values.speakers);
            const jobObj = { episode, speakerObj, customDict };
            // InputHandler.addJob(jobObj, 'users/job');
            alert('Values submitted:' + JSON.stringify(values, null, 2));
            // resetForm()
            setSubmitting(false);
          }, 1000);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* {JSON.stringify(values)} */}
            <Box {...row}>
              <Box {...col}>
                <Card>
                  <h4>Enter Speaker Names</h4>
                  <p>
                    Enter each speaker name in order of appearance, one per
                    line.
                  </p>
                  <Input
                    // size="25"
                    rows="6"
                    cols="20"
                    inputType="textarea"
                    label="Speakers"
                    isMaterial={true}
                    isFormik={true}
                    value={values.speakers}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ color: 'black' }}
                    // onChange={value =>
                    //   updateSpeakerObj(InputHandler.updateSpeakerObj(value))
                    // }
                  />
                  <p>
                    <strong>
                      You may have up to {maxSpeakers} speakers maxium.
                    </strong>
                  </p>
                </Card>
              </Box>

              <Box {...col}>
                <Card>
                  <h4>Speaker List</h4>

                  {values.speakers &&
                  values.speakers.split('\n').length <= maxSpeakers ? (
                    <p>
                      <strong>
                        {values.speakers.split('\n').length} speakers
                      </strong>
                    </p>
                  ) : (
                    <p style={errorText}>
                      <strong>Invalid number of speakers!</strong>
                    </p>
                  )}

                  {values.speakers &&
                    values.speakers.split('\n').length < maxSpeakers + 1 &&
                    values.speakers.split('\n').map(
                      (speaker, index) =>
                        index < maxSpeakers ? (
                          <Dotdotdot clamp={2} key={index}>
                            <p key={index}>
                              <strong>
                                {values.introVoice && 'Intro'} Speaker {index}:
                              </strong>{' '}
                              {speaker}
                            </p>
                          </Dotdotdot>
                        ) : (
                          <></>
                        )
                      // <Dotdotdot clamp={2}>
                      // <p style={errorText} key={index}><strong>Invalid Speaker {index}:</strong> <strike>{speaker}</strike></p>
                      // </Dotdotdot>
                    )}
                </Card>
              </Box>

              <Box {...col}>
                <Card>
                  <h4>Addtional Options</h4>
                  {/* <CheckBox
              id='introVoice'
              labelText='This episode has an intro speaker/voice'
              isMaterial={false}
              // value={values.introVoice}
              // onChange={handleChange}
              /> */}
                </Card>
              </Box>
            </Box>

            <Box {...widgetsRow}>
              <Box {...col}>
                <Button
                  title="SAVE"
                  style={{ width: '100%' }}
                  type="submit"
                  disabled={isSubmitting}
                />
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default MultipleSpeakerForm;
