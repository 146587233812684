// REACT AND GATSBY
import React from 'react';
// ELEMENTS AND COMPONENTS
import Button from 'reusecore/src/elements/Button';
import WidgetLayout from 'reusecore/src/elements/Widget/WidgetLayout';
// STYLING
// import { JobWidgetStyles } from './jobWidgets.styles';
// LOGIC AND HANDLERS
import { InputHandler } from './audioWidget.handlers';
// NPM MODULES

// const { col } = JobWidgetStyles;

// Audio Widget
export const AudioWidget = ({ episode, audioSupport }) => {
  // const { episode, audioSupport } = job;
  console.log(episode);
  // if (episode && audioSupport) {
  if (episode) {
    return (
      <WidgetLayout
        title="Audio"
        // width={}
        // width={[1, 1, 1/3, 1/3, 1/3]}
      >
        <Button
          title="PLAY"
          onClick={async () => {
            alert('fix me!');
            // await InputHandler.playEpisode(episode);
          }}
        />
        {/* <p>{'Audio Format: ' + audioSupport.extSupported}</p> */}
      </WidgetLayout>
    );
  } else {
    return <></>;
  }
};
