import styled from 'styled-components';

export const NewJobFormWrapper = styled.section`
  /* padding: 100px 0 0 0;*/
`;
NewJobFormWrapper.displayName = 'NewJobFormWrapper';

export const Styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '10px',
    mb: '10px',
  },
  col: {
    height: '100%',
    width: [1, 1, 1, 1 / 3, 1 / 3],
    pb: ['10px', '10px', '20px', '20px'],
    pr: ['10px', '10px', '20px', '20px'],
  },
  errorText: {
    color: 'red',
  },
  widgetsRow: {
    flexBox: true,
    flexWrap: 'wrap',
    mt: '10px',
    mb: '10px',
  },
};

export default NewJobFormWrapper;
