export const DefaultStyle = {
  card: {
    // backgroundColor: 'rgba(255,255,255, 0.1)',
    boxShadow: '0px 20px 35px rgba(0, 0, 0, 0.05)',
    bg: 'rgba(255,255,255, 0.1)',
    borderRadius: '10px',

    height: '100%',
    pt: '5px',
    pb: '20px',
    pl: '20px',
    pr: '20px',
    ml: ['5px', '5px', '10px', '10px'],
    mr: ['5px', '5px', '10px', '10px']

    // pt: ['10px', '10px', '20px', '20px'],
    // pb: ['10px', '10px', '20px', '20px'],
    // pl: ['10px', '10px', '20px', '20px'],
    // pr: ['10px', '10px', '20px', '20px'],
    // ml: ['10px', '10px', '20px', '20px'],
    // mr: ['10px', '10px', '20px', '20px']
  }
};
