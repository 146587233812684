// REACT AND GATSBY
import React, { useState, useEffect } from 'react';

// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
// import Text from 'reusecore/src/elements/Text';
// import Button from 'reusecore/src/elements/Button';
// import Image from 'reusecore/src/elements/Image';
// import Input from 'reusecore/src/elements/Input';
import Spinner from 'reusecore/src/elements/Spinner'
import Container from 'common/src/components/UI/Container';
// import ErrorSec from 'gatsby-landing/src/containers/Error';
import InfoTile from 'common/src/components/InfoTile';
import MultipleSpeakersForm from './speakers';
import { AudioWidget } from 'common/src/components/JobWidgets/AudioWidget';

// STYLING
import NewJobFormWrapper, { Styles } from './newJobForm.styles'; // CSS styling

// LOGIC AND HANDLERS
import { loadEpisode } from './handlers'; // Code to handle user I/O

// NPM MODULES
import moment from 'moment';
import pms from 'pretty-ms';
import stripHtml from 'string-strip-html';

// import { format } from 'timeago.js';
// import hd from 'humanize-duration';

const NewJobForm = ({ heading }) => {
  const [speakerObj, updateSpeakerObj] = useState({
    raw: 'Luke Cage',
    speakers: [],
    numSpeakers: 0,
  });
  // Custom dictionary.  We dont need this for now.
  // const [customDict, updateDict] = useState(' ');

  // Episode data
  const [episode, updateEpisode] = useState({});

  // Performs initial fetch when component loads
  useEffect(() => {
    async function initialLoad() {
      updateEpisode(await loadEpisode());
    }
    if (!episode.podcast) initialLoad();
  }, []);

  const { row, widgetsRow } = Styles;

  console.log(episode);
  if (episode.podcast) {
    return (
      <NewJobFormWrapper>
        <Container className="newjobform_container">
          {/*** TITLE BAR (optional) ***/}
          <Box {...row}>
            <h1>{heading}</h1>
          </Box>
          <Box {...row}>
            <Spinner />
            <InfoTile
              data={{
                title: episode.title,
                image: episode.image,
                description: stripHtml(episode.description),
                meta1: moment(episode.pub_date_ms).format('MMM D YYYY, HH:mm '),
                meta2: pms(episode.audio_length_sec * 1000, {
                  colonNotation: true,
                }),
              }}
              tileStyle="large"
              // onImageClick={() =>
              //   InputHandler.redirectToPage('admin/job', { jobId: episode.episode.id })
              // }
              // onTitleClick={() =>
              //   InputHandler.redirectToPage('admin/job', { jobId: episode.id })
              // }
            />
          </Box>

          {/* JOB FUNCTION COMPONENTS */}
          <Box {...widgetsRow}>
            <AudioWidget
              episode={episode}
              audioSupport={episode.audioSupport}
            />
          </Box>

          <MultipleSpeakersForm heading="Job Settings" episode={episode} />
        </Container>
      </NewJobFormWrapper>
    );
  } else {
    return (
      // DEFAULT ERROR PAGE
      <NewJobFormWrapper>
        <Spinner />
        {/* <Container>
          <ErrorSec styleType="light" />
        </Container> */}
      </NewJobFormWrapper>
    );
  }
};

export default NewJobForm;
